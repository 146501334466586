.BodyPage {
  margin: 5rem 0;
  display: flex;
  justify-content: center;

  .row {
    width: 80%;
    max-width: 1170px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 50px 30px;
    .left {
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .right {
      display: flex;
      align-items: center;

      .content {
        padding-left: 20px;

        p {
          font-size: 16px;
          line-height: 26px;
          padding-bottom: 15px;
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .BodyPage {
    .row {
      .right {
        .content {
          padding-left: 0;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .BodyPage {
    .row {
      width: 90%;
      grid-template-columns: 1fr;
    }
  }
}
