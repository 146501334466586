/* pc */
@media (min-width: 820px) {
  .Home {
    display: flex;
    flex-direction: column;
    align-items: center;
    .titel {
      font-family: "Permanent Marker", cursive;
      font-weight: 400;
      font-size: 4rem;
      color: #17202a;
      position: absolute; /* Position absolue pour le titre */
      /*padding: 10px; /* Espace intérieur pour le titre */
      z-index: 1; /* Profondeur z pour empiler au-dessus de l'image */
    }
    p {
      color: #17202a;
      font-family: "Pacifico", cursive;
      position: absolute; /* Position absolue pour le titre */
      z-index: 2; /* Profondeur z pour empiler au-dessus de l'image */
      padding: 4px; /* Espace intérieur pour le titre */
      font-size: 1.5rem;
    }
    .imagHome {
      margin-top: 6rem;
      width: 55rem;
    }
  }
}

/* tablet */
@media (max-width: 820px) {
  .Home {
    display: flex;
    flex-direction: column;
    align-items: center;
    .titel {
      font-family: "Permanent Marker", cursive;
      font-weight: 400;
      font-size: 4rem;
      color: #17202a;
      position: absolute; /* Position absolue pour le titre */
      /*padding: 10px; /* Espace intérieur pour le titre */
      z-index: 1; /* Profondeur z pour empiler au-dessus de l'image */
    }
    p {
      color: #17202a;
      font-family: "Pacifico", cursive;
      position: absolute; /* Position absolue pour le titre */
      z-index: 2; /* Profondeur z pour empiler au-dessus de l'image */
      padding: 4px; /* Espace intérieur pour le titre */
      font-size: 1.5rem;
    }
    .imagHome {
      margin-top: 6rem;
      width: 45rem;
    }
  }
}

/* phone */
@media (max-width: 573px) {
  .Home {
    display: flex;
    flex-direction: column;
    align-items: center;
    .titel {
      font-family: "Permanent Marker", cursive;
      font-weight: 400;
      color: #17202a;
      position: absolute; /* Position absolue pour le titre */
      /*padding: 10px; /* Espace intérieur pour le titre */
      z-index: 1; /* Profondeur z pour empiler au-dessus de l'image */

      font-size: 2.5rem;
    }
    p {
      color: #17202a;
      font-family: "Pacifico", cursive;
      position: absolute; /* Position absolue pour le titre */
      z-index: 2; /* Profondeur z pour empiler au-dessus de l'image */
      padding: 38px; /* Espace intérieur pour le titre */
    }
    .imagHome {
      margin-top: 3.5rem;
      width: 22rem;
    }
  }
}
