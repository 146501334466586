@media (min-width: 573px) {
  .erreurPage {
    /*border: 1px solid red;*/
    display: flex;
    justify-content: center;
    align-items: center;
    .helloPage {
      margin: 10rem;
      width: 40rem;
    }
  }
}
/* phone */
@media (max-width: 573px) {
  .erreurPage {
    /*border: 1px solid red;*/
    display: flex;
    justify-content: center;
    align-items: center;
    .helloPage {
      margin: 2rem;
      width: 13rem;
    }
  }
}
