.bodyGallery {
  margin: 3rem 0;
  .gallery {
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    gap: 2vmin;
    padding: 0 14px;

    li {
      list-style-type: none;
      height: 250px;
      flex-grow: 1;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
