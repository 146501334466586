/* style.scss */
/* desktop */
@media (min-width: 573px) {
  .navbar {
    color: black;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    text-decoration: none;

    padding: 1rem;
  }

  .link {
    text-decoration: none;
    color: #17202a;
    margin: 0 8px;
    font-size: 1rem;
    font-weight: 700;

    width: 10rem;
    height: 3rem;

    font-weight: 700;

    font-family: "Work Sans", sans-serif;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link:hover {
    border-radius: 50px;
    border: 1px solid #17202a;
    background-color: #17202a;
    color: #fbfcfc;
  }

  .linkActive {
    border-radius: 50px;
    border: 1px solid #17202a;
    background-color: #17202a;
    color: #fbfcfc;

    text-decoration: none;

    margin: 0 8px;
    font-size: 1rem;
    font-weight: 700;

    width: 10rem;
    height: 3rem;

    font-weight: 700;

    font-family: "Work Sans", sans-serif;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

/* phone */
@media (max-width: 573px) {
  .navbar {
    color: black;
    display: flex;
    justify-content: center;
    padding: 10px 0;
    text-decoration: none;
  }

  .link {
    text-decoration: none;
    color: #17202a;
    margin: 0 2px;
    font-size: 0.6rem;
    font-weight: 600;

    width: 6rem;
    height: 2rem;

    font-weight: 700;

    font-family: "Work Sans", sans-serif;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .link:hover {
    border-radius: 50px;
    border: 1px solid #17202a;
    background-color: #17202a;
    color: #fbfcfc;
  }

  .linkActive {
    border-radius: 50px;
    border: 1px solid #17202a;
    background-color: #17202a;
    color: #fbfcfc;

    text-decoration: none;

    margin: 0 8px;
    font-size: 0.6rem;
    font-weight: 600;

    width: 6rem;
    height: 2rem;

    font-weight: 700;

    font-family: "Work Sans", sans-serif;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}
