/* other */

@media (min-width: 677px) {
  .containerContact {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 5rem 0 2rem 0;

    .leftDiv {
      /*border: 1px solid red;*/
      width: 30rem;
      height: 60vh;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0.5rem;

      .dateAndHour {
        text-align: center;
        font-size: 1rem;
        font-weight: 600;
        font-family: sans-serif;
      }
    }

    .rightDiv {
      /*border: 1px solid red;*/
      width: 40rem;
      height: 60vh;
      display: flex;
      align-items: center;

      form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
          display: flex;
          flex-direction: column;
          margin: 0.5rem;

          input {
            width: 20rem;
            height: 1.5rem;
          }
          textarea {
            width: 20rem;
            height: 4rem;
          }
        }
      }
    }

    .button {
      width: 10rem;
      height: 2rem;
      margin: 1rem 0 0 0.5rem;
      border: none;
      font-weight: 600;
      background-color: #17202a;
      color: aliceblue;
    }
    .button:hover {
      background-color: aliceblue;
      color: #17202a;
      border: 1px solid #17202a;
      cursor: pointer;
    }
  }
}

/* phone */
@media (max-width: 677px) {
  .containerContact {
    display: flex;
    width: 100%;
    justify-content: center;
    margin: 5rem 0 2rem 0;
    flex-direction: column;

    .leftDiv {
      /*border: 1px solid red;*/
      width: 90%;
      height: 25rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 1rem;

      .dateAndHour {
        font-size: 1rem;
        font-weight: 600;
        font-family: sans-serif;
      }
    }

    .rightDiv {
      /*border: 1px solid red;*/
      width: 100%;
      height: 30rem;
      display: flex;
      align-items: center;
      justify-content: center;

      form {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        label {
          display: flex;
          flex-direction: column;
          margin: 0.8rem;

          input {
            width: 20rem;
            height: 1.5rem;
          }
          textarea {
            width: 20rem;
            height: 4rem;
          }
        }
      }
    }

    .button {
      width: 10rem;
      height: 2rem;
      margin: 1rem 0 0 1rem;
      border: none;
      font-weight: 600;
      background-color: #17202a;
      color: aliceblue;
    }
    .button:hover {
      background-color: aliceblue;
      color: #17202a;
      border: 1px solid #17202a;
      cursor: pointer;
    }
  }
}
