/* phone */
@media (min-width: 573px) {
  .homeComponent {
    display: flex;
    flex-direction: column;
    align-items: center;
    .titel {
      font-family: "Permanent Marker", cursive;
      font-weight: 400;
      font-size: 3rem;
      color: #17202a;
      margin-top: 3rem;
    }
    .subTitle {
      color: #17202a;
      font-family: "Work Sans", sans-serif;
      padding: 0.5rem; /* Espace intérieur pour le titre */
      text-align: center;
      position: absolute; /* Position absolue pour le titre */
      margin-top: 7rem;
      font-size: 0.9rem;
      font-weight: 700;
      width: 20rem;
    }
    .paragraph {
      margin: 1.5rem;
      padding: 2rem 0 2rem 0;
      font-family: "work Sans", sans-serif;
      font-weight: 400;
      font-size: 1.2rem;
      text-align: center;

      width: 80%;
    }
  }

  .transportsComponent {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1.5rem 0 1.5rem 0;
    text-align: center;
    .titel {
      font-family: "Permanent Marker", cursive;
      font-weight: 400;
      font-size: 2.5rem;
      color: #17202a;
      margin-top: 1rem;
    }
    .subTitle {
      color: #17202a;
      font-family: "Work Sans", sans-serif;
      padding: 0.5rem; /* Espace intérieur pour le titre */
      text-align: center;

      font-size: 1rem;
      font-weight: 700;
      width: auto;
    }

    .card {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
    }

    .cardBest {
      width: 25rem;
      height: 30rem;
    }
  }

  .partenairesComponent {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0 1.5rem 0;
    text-align: center;
    padding-bottom: 1rem;

    .titel {
      font-family: "Permanent Marker", cursive;
      font-weight: 400;
      color: #17202a;
      margin-top: 1rem;
      font-size: 2.5rem;
    }
    .subTitle {
      color: #17202a;
      font-family: "Work Sans", sans-serif;
      padding: 0.5rem; /* Espace intérieur pour le titre */
      text-align: center;
      font-size: 1.2rem;
      font-weight: 700;
      width: auto;
    }
    .logoCard {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: space-evenly;
      align-items: center;

      .tailleCard {
        width: 20rem;
        height: 6rem;

        object-fit: cover;
      }
    }

    .paragraph {
      margin: 1.5rem;
      padding: 3rem;
      font-family: "work Sans", sans-serif;
      font-weight: 400;
      font-size: 1.2rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      button {
        margin-top: 3rem;
        width: 15rem;
        height: 3.5rem;
        text-align: center;

        border: none;
        background-color: #c0392b;
        color: white;
        font-family: "work Sans", sans-serif;
        font-size: 1rem;
        font-weight: 700;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      }
      button:hover {
        color: #17202a;
        background-color: #e6b0aa;
        border: 1px solid #e6b0aa;
        cursor: pointer;
      }
    }
  }
}
/* phone */
@media (max-width: 573px) {
  .homeComponent {
    display: flex;
    flex-direction: column;
    align-items: center;

    .titel {
      font-family: "Permanent Marker", cursive;
      font-weight: 400;
      color: #17202a;
      margin-top: 1rem;
    }
    .subTitle {
      color: #17202a;
      font-family: "Work Sans", sans-serif;
      padding: 0.5rem; /* Espace intérieur pour le titre */
      text-align: center;
      position: absolute; /* Position absolue pour le titre */
      margin-top: 3.5rem;
      font-size: 0.7rem;
      font-weight: 700;
      width: 20rem;
    }
    .paragraph {
      margin: 1.5rem;
      padding: 0.5rem 0 0.5rem 0;
      font-family: "work Sans", sans-serif;
      font-weight: 400;
      font-size: 0.9rem;
      text-align: center;
    }
  }

  .transportsComponent {
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 1.5rem 0 1.5rem 0;
    text-align: center;
    .titel {
      font-family: "Permanent Marker", cursive;
      font-weight: 400;
      color: #17202a;
      margin-top: 1rem;
    }
    .subTitle {
      color: #17202a;
      font-family: "Work Sans", sans-serif;
      padding: 0.5rem; /* Espace intérieur pour le titre */
      text-align: center;

      font-size: 0.7rem;
      font-weight: 700;
      width: auto;
    }

    .card {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .cardBest {
      width: 25rem;
      height: 30rem;
    }
  }

  .partenairesComponent {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0 1.5rem 0;
    text-align: center;
    padding-bottom: 1rem;
    .titel {
      font-family: "Permanent Marker", cursive;
      font-weight: 400;
      color: #17202a;
      margin-top: 1rem;
    }
    .subTitle {
      color: #17202a;
      font-family: "Work Sans", sans-serif;
      padding: 0.5rem; /* Espace intérieur pour le titre */
      text-align: center;
      font-size: 0.7rem;
      font-weight: 700;
      width: auto;
    }
    .logoCard {
      display: flex;
      flex-wrap: wrap;
      align-content: center;
      justify-content: space-evenly;
      align-items: center;
      .tailleCard {
        width: 20rem;
        height: 6rem;

        object-fit: cover;
      }
    }

    .paragraph {
      margin: 1.5rem;
      padding: 0.5rem 0 0.5rem 0;
      font-family: "work Sans", sans-serif;
      font-weight: 400;
      font-size: 0.9rem;

      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      button {
        margin: 1rem;
        width: 15rem;
        height: 3.5rem;
        text-align: center;

        border: none;
        background-color: #c0392b;
        color: white;
        font-family: "work Sans", sans-serif;
        font-size: 1rem;
        font-weight: 700;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
          rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
      }
      button:hover {
        color: #17202a;
        background-color: #e6b0aa;
        border: 1px solid #e6b0aa;
        cursor: pointer;
      }
    }
  }
}
