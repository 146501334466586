/* style.scss */
@media (min-width: 573px) {
  .contactComponent {
    text-align: center;
    /*border: 1px solid red;*/
    width: auto;
    height: 35rem;
    background-color: white;

    background-image: url("../../Assets/Capture d’écran du 2023-10-22 09-55-52.png");
    /*background-size: contain;*/
    /*background-size: contain;*/

    background-repeat: no-repeat;
    background-position: center bottom;

    .titel {
      font-family: "Permanent Marker", cursive;
      font-weight: 400;
      font-size: 2.5rem;
      color: #17202a;
      margin-top: 1rem;
      padding-top: 2rem;
    }
    .contactUs {
      /*border: 1px solid red;*/
      width: auto;
      height: 10rem;
      display: flex;
      flex-direction: row;
      .contactPhone {
        width: 100%;
        height: 100%;
        /*border: 1px solid yellow;*/
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 1rem;
        margin: 0.5rem 0 0 4rem;
        justify-content: center;
        span {
          font-weight: 600;
          font-size: 1.2rem;
        }
      }

      .contactMail {
        width: 50%;
        height: 100%;
        /*border: 1px solid red;*/
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .socIcone {
          /* border: 1px solid red;*/

          padding: 0.5rem;
          display: flex;
          width: 70%;
          justify-content: space-evenly;
          margin-bottom: 2rem;
          img {
            width: 2.5rem;
          }
        }

        button {
          margin-top: 0.5rem;
          width: 16rem;
          height: 4rem;

          text-align: center;

          border: none;
          background-color: #17202a;
          color: white;
          font-family: "work Sans", sans-serif;
          font-size: 1rem;
          font-weight: 700;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
            rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }
        button:hover {
          color: #17202a;
          background-color: #eaecee;
          border: 1px solid #eaecee;
          cursor: pointer;
        }
      }
    }
  }

  .footer {
    background-color: #17202a;
    padding: 1.5rem;
    position: fixed;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;

    p {
      margin: 0;
      color: #fbfcfc;
      font-family: "Work Sans", sans-serif;
      font-size: 1rem;
      font-weight: 400;
    }
  }
}

/* phone */
@media (max-width: 573px) {
  .contactComponent {
    text-align: center;
    /*border: 1px solid red;*/
    width: auto;
    height: 25rem;
    background-color: white;

    background-image: url("../../Assets/Capture d’écran du 2023-10-22 09-55-52.png");
    /*background-size: contain;*/
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center bottom;

    .titel {
      font-family: "Permanent Marker", cursive;
      font-weight: 400;
      color: #17202a;
      margin-top: 1rem;
      padding-top: 2rem;
    }
    .contactUs {
      /*border: 1px solid red;*/
      width: auto;
      height: 10rem;
      display: flex;
      flex-direction: row;
      .contactPhone {
        width: 45%;
        height: 100%;
        /*border: 1px solid yellow;*/
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 0.7rem;
        margin: 0.5rem;
        justify-content: center;

        span {
          font-weight: 600;
          font-size: 0.8rem;
        }
      }

      .contactMail {
        width: 50%;
        height: 100%;
        /*border: 1px solid red;*/
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .socIcone {
          padding: 0.5rem;
          display: flex;
          width: 100%;
          justify-content: space-evenly;
          img {
            width: 1.5rem;
          }
        }

        button {
          margin-top: 0.5rem;
          width: 8rem;
          height: 3.5rem;

          text-align: center;

          border: none;
          background-color: #17202a;
          color: white;
          font-family: "work Sans", sans-serif;
          font-size: 0.7rem;
          font-weight: 700;
          box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
            rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
        }
        button:hover {
          color: #17202a;
          background-color: #eaecee;
          border: 1px solid #eaecee;
          cursor: pointer;
        }
      }
    }
  }

  .footer {
    background-color: #17202a;
    padding: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 95%;

    p {
      margin: 0;
      color: #fbfcfc;
      font-family: "Work Sans", sans-serif;
      font-size: 0.6rem;
      font-weight: 400;
    }
  }
}
